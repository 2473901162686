<template>
  <section class="flex flex-col">
    <BarCode
      v-if="concession.codes?.[0]?.__typename === 'Barcode'"
      :image="concession.codes[0]?.image"
      :code="concession.codes[0]?.code"
    />

    <span class="!print:text-black" v-text="concessionString" />
  </section>
</template>

<script setup lang="ts">
import type { OrderConcession } from '#gql/default'

interface Props {
  concession: OrderConcession
}

const { concession } = defineProps<Props>()

const concessionString = computed(() => {
  return `${concession.quantity}x ${concession.concession.name} ${concessionDescription.value ? `(${concessionDescription.value})` : ''}`
})

const concessionDescription = computed(() => {
  if (!concession.subItemSelection || concession.subItemSelection.length <= 0) {
    return null
  }

  const temp = concession.subItemSelection.map(({ option }) => {
    return `${option?.name}`
  })

  if (temp.length <= 0) {
    return null
  }

  return temp.join(', ')
})

defineOptions({
  name: 'OrderConcession',
})
</script>
