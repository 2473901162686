<template>
  <div class="flex flex-col space-y-4 py-4" v-if="order.concessions.length">
    <div class="flex flex-col">
      <h2
        class="heading-lg text-balance pb-1 leading-none print:!text-black print:dark:!text-black"
        v-text="t('headline')"
      />

      <span
        v-if="isFreeSeating"
        class="text-lg"
        v-html="ct('MAIL_BOOKING_CONCESSIONS_INFO_NOT_SEATED', null, '')"
      />

      <span
        v-else
        class="text-lg"
        v-html="ct('MAIL_BOOKING_CONCESSIONS_INFO_SEATED', null, '')"
      />
    </div>

    <Divider />

    <div v-for="concession in order.concessions">
      <OrderConcession :concession />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()

const isFreeSeating = computed(() => {
  return order.tickets.every((ticket) => ticket.seat === null)
})

defineOptions({
  name: 'OrderConfirmationConcessions',
})
</script>

<i18n>
de:
  headline: 'Mitgebuchte Menüs'
es:
  headline: 'Menús compartidos'
en:
  headline: 'Menus'
</i18n>
