import revive_payload_client_gQP1Jg12rz from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zYTXQWdSDw from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wBCzWccifT from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_W0eY1FzIDC from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1AG4r91e4T from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Mu3wkz1Jtf from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_GqpNA8NIRX from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_gdON4oABJr from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/apps/kinoheld/.nuxt/components.plugin.mjs";
import prefetch_client_02ixH89rFG from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.10.7_eslint@9.18.0_typescript@5.5.4_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9grL8oxmTO from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@6.0.11_vue@3.4.38/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/apps/kinoheld/.nuxt/formkitPlugin.mjs";
import plugin_vvzyYstfz4 from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@babel+core@7.26.0_@types+node@22.10.7_graphql-tag@2.12.6_typescript@5.5.4/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_GTxKeb5Qzl from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_vue-router@4.4.3_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_FgLpto0kRT from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_vue-router@4.4.3_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/kinoheld/dev/2025-01-23_09-56-02_1c75347/layers/base/plugins/sentry.client.ts";
export default [
  revive_payload_client_gQP1Jg12rz,
  unhead_zYTXQWdSDw,
  router_wBCzWccifT,
  payload_client_W0eY1FzIDC,
  navigation_repaint_client_1AG4r91e4T,
  check_outdated_build_client_Mu3wkz1Jtf,
  view_transitions_client_GqpNA8NIRX,
  chunk_reload_client_gdON4oABJr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_02ixH89rFG,
  plugin_9grL8oxmTO,
  formkitPlugin_pZqjah0RUG,
  plugin_vvzyYstfz4,
  composition_GTxKeb5Qzl,
  i18n_FgLpto0kRT,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV
]